import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  Container,
  Heading,
  Subheading,
  Text,
} from "../../components/styled/Styled"
import { MEDIA_QUERIES, COLORS } from "../../constants"

const CVItem = styled.p`
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: ${COLORS.PRIMARY};
`

const ImgContainer = styled(motion.div)`
  position: relative;
  width: 40%;
  padding-top: 2rem;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 90%;
    margin: auto;
    padding-top: 2rem;
  }
`

const Section = styled(motion.div)`
  display: flex;
  border-right: 8px solid ${COLORS.BLACK};
  flex-wrap: wrap;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column-reverse;
    border-right: none;
    margin: auto;
  }
`

const Article = styled(motion.div)`
  display: flex;
  width: 60%;
  flex-direction: column;
  padding: 0 3rem;
  text-align: right;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column;
    width: 100%;
    margin: auto;
    padding: 0 1rem;
    text-align: center;
  }
`

const CV = styled(Article)`
  text-align: left;
  div {
    max-height: 550px;
    overflow-y: auto;
  }

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    div {
      max-height: 2000px;
    }
  }
`
const StyledSubheading = styled(motion.div)`
  margin-right: 1rem;
  text-align: right;
  width: 100%;
  color: ${COLORS.PRIMARY};
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    display: none;
  }
`

const StyledHeading = styled(Heading)`
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    padding-top: 0;
  }
`

const headingVar = {
  initial: { opacity: 0, x: "-300px" },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 2,
      delay: 1,
      type: "spring",
      stiffness: 40,
    },
  },
}

const textVar = {
  initial: { opacity: 0, x: "-300px" },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 2,
      delay: 1.2,
      type: "spring",
      stiffness: 40,
    },
  },
}

const imgVar = {
  initial: { opacity: 0, x: "-300px" },
  animate: {
    opacity: 1,
    x: 0,
    transition: { duration: 2, type: "spring", stiffness: 40 },
  },
}

const articleVar = {
  initial: { opacity: 0, y: "-300px" },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 2, type: "spring", stiffness: 40 },
  },
}

const FreestylePage = () => {
  const { profile, cv } = useStaticQuery(graphql`
    query {
      profile: file(relativePath: { eq: "freestyler1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      cv: file(relativePath: { eq: "freestyler2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Freestyler" />

      <Container>
        <Section>
          <StyledSubheading>FREESTYLER</StyledSubheading>
          <Article initial="initial" animate="animate" variants={articleVar}>
            <Heading initial="initial" animate="animate" variants={headingVar}>
              About me
            </Heading>
            <Text initial="initial" animate="animate" variants={textVar}>
              I’ve started dancing and training for competitions since 2008. I
              travelled the world with several teams and started training teams
              on my own. I learned a lot from it, and I still do! Although I
              just love choreo competitions, I had the feeling I missed a tool
              to express my true selve. That’s why 3 years ago I started
              investing in freestyle. I started freestyling and experimenting in
              hiphop, locking, popping and house, but I discovered my true
              passion when I met waacking!
            </Text>
            <Text initial="initial" animate="animate" variants={textVar}>
              I did my first waacking classes in LA by Lorena Valenzuela in 2017
              and I just fell in love with the style, the history and the
              freedom.{" "}
            </Text>
            <Text initial="initial" animate="animate" variants={textVar}>
              Back in Belgium I met Katerina Ourda. THE waacking queen in
              Belgium. She introduced me to the waacking scene and motivated me
              to go to international jams,workshops and battles. That’s where I
              met a lot of inspirational people from around the world. Waacking
              became my passion. It’s a style where I can be free, let my
              emotions speak, party and were I can involve my femininity in the
              dance. I am grateful to be a part of this scene.
            </Text>
          </Article>
          <ImgContainer style={{ marginTop: "auto" }}>
            <Img fluid={profile.childImageSharp.fluid} />
          </ImgContainer>
        </Section>
        <Section style={{ paddingTop: "2rem" }}>
          <ImgContainer initial="initial" animate="animate" variants={imgVar}>
            <Img fluid={cv.childImageSharp.fluid} />
          </ImgContainer>
          <CV>
            <StyledHeading
              initial="initial"
              animate="animate"
              variants={headingVar}
            >
              Curriculum Vitae
            </StyledHeading>
            <div>
              <Subheading style={{ margin: "0rem 0 1rem 0" }}>2020</Subheading>
              <CVItem>Winner: Strike With Force (Italy)</CVItem>
              <CVItem>
                Top 16: The Waackdown (funk category) (Eindhoven,NL)
              </CVItem>
              <CVItem>Top 8: The bleeding hearts club (Denmark)</CVItem>
              <CVItem>Top 16: Respect my style ONLINE (BE)</CVItem>
              <CVItem>
                Top 32: Pair Of Waacks battle ONLINE (BE, international)
              </CVItem>
              <CVItem>
                Top 8: Unite the scene - For the funk ONLINE (UK, international)
              </CVItem>
              <CVItem>
                Top 16: International Dance Battle ONLINE (India, international)
              </CVItem>
              <CVItem>
                Top 16: Don’t stop waack battle (Ukraine, international)
              </CVItem>
              <Subheading style={{ margin: "1.5rem 0 1rem 0" }}>
                2019
              </Subheading>
              <CVItem>
                Top 16: Bonnie & Clyde battle (Louvain-la-Neuve, BE)
              </CVItem>
              <CVItem>Top 8: Dansbaar (Leuven, BE)</CVItem>
              <CVItem>
                Semi-Finals: KAMATA Funkstyle battle (Brussels, BE)
              </CVItem>
              <Subheading style={{ margin: "1.5rem 0 1rem 0" }}>
                2018
              </Subheading>
              <CVItem>Top 16: To The Moon and Waack (Amsterdam, NL)</CVItem>
            </div>
          </CV>
        </Section>
      </Container>
    </Layout>
  )
}

export default FreestylePage
